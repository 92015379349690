import React, { useEffect, useState } from "react";
import {
  Typography,
  CardContent,
  Grid,
  Container,
  Paper,
  Button,
  Alert,
} from "@mui/material";
import axios from "../utils/axiosConfig";

const RechargePage = () => {
  const [loading, setLoading] = useState(false);
  const [basicOffers, setBasicOffers] = useState([]);
  const [additionalOffers, setAdditionalOffers] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null); // لحساب الوقت المتبقي

  const basicAmounts = [5, 10, 25, 50, 100, 300]; // القيم الأساسية
  const endDate = new Date("2024-12-23T10:30:35");

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axios.get("/api/charge");
        const allOffers = response.data;

        const basic = allOffers.filter((offer) =>
          basicAmounts.includes(offer.Price)
        );
        const additional = allOffers.filter(
          (offer) => !basicAmounts.includes(offer.Price)
        );

        setBasicOffers(basic);
        setAdditionalOffers(additional);
      } catch (error) {
        console.error("Error fetching recharge offers:", error);
      }
    };

    fetchOffers();

    const intervalId = setInterval(() => {
      const currentTime = new Date();
      const timeDiff = endDate - currentTime;

      if (timeDiff <= 0) {
        setAdditionalOffers([]);
        clearInterval(intervalId);
      } else {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleRecharge = async (amount, points, retryCount = 3) => {
    setLoading(true);
    setButtonDisabled(true);
    try {
      alert(
        "Recharge via PayPal is currently disabled. Please contact us on Discord for assistance."
      );
    } catch (error) {
      console.error("Error during recharge:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
        setButtonDisabled(false);
      }, 2000);
    }
  };

  const getCardStyle = (isAdditional) => ({
    background: isAdditional
      ? "linear-gradient(135deg, #000000, #333333)"
      : "linear-gradient(135deg, #2B0050, #4B0082)",
    color: isAdditional ? "#FFD700" : "#FFF",
    boxShadow: isAdditional
      ? "0 0 20px rgba(0, 0, 0, 0.8)"
      : "0 0 20px rgba(43, 0, 80, 0.5)",
    borderRadius: "8px",
  });

  const renderOffers = (offers, isAdditional) =>
    offers.map((offer) => (
      <Grid item xs={12} sm={6} md={4} key={offer.ID}>
        <div
          style={{
            ...getCardStyle(isAdditional),
            padding: "20px",
            textAlign: "center",
            cursor: loading || buttonDisabled ? "not-allowed" : "pointer",
            transition: "transform 0.3s, box-shadow 0.3s",
          }}
        >
          <CardContent>
            <Typography
              variant="h3"
              component="div"
              align="center"
              style={{ fontWeight: "bold" }}
            >
              ${offer.Price}
            </Typography>
            <Typography
              variant="h6"
              align="center"
              style={{
                color: isAdditional ? "#FFD700" : "#FFF",
                fontWeight: isAdditional ? "bold" : "normal",
              }}
            >
              Get {offer.Points} points
            </Typography>

            {offer.ItemCode && (
              <Typography
                variant="body1"
                align="center"
                style={{
                  color: isAdditional ? "#FFD700" : "#FFF",
                  fontWeight: "normal",
                  marginTop: "10px",
                }}
              >
                {offer.ItemCode}
              </Typography>
            )}

            <Button
              variant="contained"
              color="secondary"
              fullWidth
              style={{
                marginTop: "15px",
                fontWeight: "bold",
                backgroundColor: isAdditional ? "#FFD700" : undefined,
                color: isAdditional ? "black" : undefined,
              }}
              onClick={() =>
                !loading &&
                !buttonDisabled &&
                handleRecharge(offer.Price, offer.Points)
              }
              disabled={loading || buttonDisabled}
            >
              Recharge Now!
            </Button>
          </CardContent>
        </div>
      </Grid>
    ));

  return (
    <Container maxWidth="lg" style={{ padding: "20px", height: "90%" }}>
      {/* رسالة التنبيه */}
      <Alert
        severity="info"
        style={{
          marginBottom: "20px",
          fontWeight: "bold",
          fontSize: "2rem",
          textAlign: "center",
        }}
      >
        The only available recharge method now is <strong>Binance</strong>. If
        you wish to donate, please open a ticket on Discord for assistance.
      </Alert>

      <Paper elevation={3} style={{ padding: "20px", borderRadius: "8px" }}>
        <Typography variant="h4" gutterBottom align="center" color="primary">
          Recharge Your Points
        </Typography>

        <Typography variant="h5" gutterBottom align="center" color="secondary">
          Basic Recharge Options
        </Typography>
        <Grid container spacing={2}>
          {renderOffers(basicOffers, false)}
        </Grid>

        <Typography
          variant="h5"
          gutterBottom
          align="center"
          color="secondary"
          style={{ marginTop: "20px" }}
        >
          Special Recharge Promotions
        </Typography>

        {timeRemaining && (
          <Typography
            variant="h6"
            gutterBottom
            align="center"
            color="error"
            style={{
              fontWeight: "bold",
              fontFamily: "Arial, sans-serif",
              textShadow: "0px 0px 10px rgba(255, 0, 0, 0.8)",
            }}
          >
            Time remaining: {timeRemaining}
          </Typography>
        )}

        <Grid container spacing={2}>
          {renderOffers(additionalOffers, true)}
        </Grid>
      </Paper>
    </Container>
  );
};

export default RechargePage;
